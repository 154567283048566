import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Toast, Row, Col, Alert } from 'react-bootstrap'
import { cap, pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/kezia.aac'
import logoig from '../assets/img/dinny/logoig.svg'
import burung from '../assets/img/dinny/burung.svg'
import AOS from 'aos';
import { gambar } from '../params'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import post from '../params/post'
import "aos/dist/aos.css";
import getData from '../params/getdata'
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#fce7d2ff'
let mainfont = '#AD3243'
let csub = " #E3B62D"

let id = 'raisa-galuh'
let inisial_co = 'Galuh'
let inisial_ce = 'Raisa'

let lengkap_co = (<>Galuh Anggara, S.T. </>)
let lengkap_ce = (<>Raisa Anindya Putri, S.T.</>)

let bapak_co = 'Bpk Achmad Pandu Rieswandhi'
let ibu_co = 'Ibu Lusy Tristiyanti'
let bapak_ce = "Bpk Achmad Hidayat"
let ibu_ce = "Ibu Nia Kartina (Alm)"

let ig_co = "galuhangg"
let ig_ce = "raisaanindya"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "01/10/2021"

let modal = gambar(pw(id, "modal.jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720')
let openlogo = pw(id, "logo.png")

let gmaps = "https://maps.app.goo.gl/mqLYfNSupLzutd4v8"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MTVvM2U5OHZsaWxlNnZrcjYydHVuZG00OWsgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7920.7499327831665!2d107.5809497!3d-6.9650165!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e8d3918870d9%3A0x505ada2035427187https%3A!2sGrand%20Ballroom%20La%20Gardena%20Kopo%20Square%2C%20Jl.%20Raya%20Kopo%2C%20Margahayu%20Tengah%2C%20Kec.%20Margahayu%2C%20Bandung%2C%20Jawa%20Barat%2040225!5e0!3m2!1sen!2sid!4v1608383146716!5m2!1sen!2sid"


export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()
        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2,
            showgift: false,
            show: false,
            show1: false,
            show2: false,
            shownew: false,
            comment: []
        }
    }
    async componentDidMount() {
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            var countDownDate = new Date(waktunikah).getTime();
            // Update the count down every 1 second
            var x = setInterval(() => {
                // Get today's date and time
                var now = new Date().getTime();
                // Find the distance between now and the count down date
                var distance = countDownDate - now;
                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                this.setState({
                    days: days, hours: hours, minutes: minutes, seconds: seconds
                })
            }, 1000);
            let comment = await getData(`kepada:"${id}"`)
            this.setState({ comment: comment })
        } catch (error) {
            console.log(error)
        }

    }
    play = () => {
        AOS.refresh()
        var snd = new Audio(music);
        snd.type = 'audio/aac';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir} ${this.state.hadir?'sesi'+this.state.sesi:''}", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                              )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi, show, show1, comment, shownew } = this.state

        let slider = []
        for (let index = 2; index < 13; index++) {
            slider[index] = gambar(pw(id, index + ".jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720')
        }
        slider[13]= gambar(pw(id,"IMG_2309.jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720')
        let query = this.useQuery().get('u');
        let time = this.useQuery().get('time');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undang.in/${id}`}
                />

                <div id='gold5' style={{
                    backgroundColor: cmain,
                    backgroundImage: `url('https://svgshare.com/i/RoG.svg')`,
                    backgroundSize: '25%'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0',color:'#B99225' }}>
                                        <br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button roboto-slab text-center btn ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                  </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>
                                        <p className="fs24 text-center">
                                            <span className="fs16" style={{ color: '#86835dff' }}>
                                                “And of His signs is that He created for you from yourselves mates that youmay
                                                find tranquility in them; and He placed between you affection and mercy.Indeed
                                                in that are signs for a people who give thought.”
                                            <br /><br />(Ar-Rum: 21)
                                            </span>
                                        </p>
                                    </Item>
                                    <Container id='sectiongold57'>
                                        <div className='py-3'>
                                            <Item>

                                                <div data-aos="fade-left" data-aos-duration="1000"
                                                    className='col-10 col-lg-8 kotak' style={{ backgroundColor: csub }}>


                                                    <Item>
                                                        <div className='item'>
                                                            <Item>
                                                                <div>
                                                                    {days}
                                                                </div>
                                                            </Item>
                                                            <Item>
                                                                <span>
                                                                    Days
                                    </span>
                                                            </Item>
                                                        </div>
                                                        <div className='dot'>:</div>
                                                        <div className='item'>
                                                            <Item>
                                                                <div>
                                                                    {hours}
                                                                </div>
                                                            </Item>
                                                            <Item>
                                                                <span>
                                                                    Hours
                          </span>
                                                            </Item>
                                                        </div>
                                                        <div className='dot'>:</div>
                                                        <div className='item'>
                                                            <Item>
                                                                <div >
                                                                    {minutes}
                                                                </div>
                                                            </Item>
                                                            <Item>
                                                                <span>
                                                                    Mins
                          </span>
                                                            </Item>
                                                        </div>
                                                        <div className='dot' >:</div>
                                                        <div className='item'>
                                                            <Item>
                                                                <div>
                                                                    {seconds}
                                                                </div>
                                                            </Item>
                                                            <Item>
                                                                <span>
                                                                    Secs
                          </span>
                                                            </Item>
                                                        </div>
                                                    </Item>


                                                </div>

                                            </Item>
                                        </div>
                                    </Container>
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: '#86835dff' }}>
                                            The Intimate Wedding of {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >
                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2' style={{ backgroundImage: `url('https://rbmg.fr/wp-content/plugins/goodlayers-core/include/images/pattern/pattern-9.png')` }}>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={foto_ce} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '36px', fontFamily: "'Marck Script', cursive",
                                                        color: mainfont
                                                    }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)', fontStyle: 'normal' }}>
                                                        <b>The Daughter of: </b><br />
                                                        {bapak_ce}<br />
                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2' style={{ backgroundImage: `url('https://rbmg.fr/wp-content/plugins/goodlayers-core/include/images/pattern/pattern-9.png')` }}>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={foto_co} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{ fontSize: '36px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)', fontStyle: 'normal' }}>
                                                        <b>The Son of : </b><br />
                                                        {bapak_co}
                                                        <br />
                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: '#86835dff' }} >
                                    <Item>
                                        <p className="fs16">
                                            The wedding will be held on :
                    </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20">
                                            <b>
                                                Sunday <span className="fs36">10</span> JAN 2021
                      </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20 col-sm-4" style={{ color: '#86835dff' }}>
                                            <b className="allura" style={{ fontSize: '46px' }}>Akad Nikah</b><br />
                                            <div style={{ fontSize: '14px' }}>
                                                (family only)
                      </div>
                                            <div className="py-3" style={{ fontSize: '24px' }}>
                                                08.00 - finish
                       </div>
                                            <br />
                                            <span className=" fs16">
                                                <b>
                                                    La Gardena Kopo Square <br />
                                                </b>
                        Jl. Raya Kopo Bihbul No. 45 Bandung</span>
                                        </p>
                                        <p className="px-3 d-none d-sm-block" style={{ color: csub, fontSize: '72px' }}>
                                            \
                    </p>
                                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid #86835dff` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: '#86835dff' }}>
                                            <b className="allura" style={{ fontSize: '46px' }}> Intimate Reception</b><br />

                                            <div className="py-3" style={{ fontSize: '24px' }}>
                                                Sesi 1: 11.00 - 12.00 (Family Only)
                      <br />Sesi 2: 12.00 - 14.00
                        </div>
                                            <br />
                                            <span className=" fs16">
                                                <b>
                                                    La Gardena Kopo Square <br />
                                                </b>
                        Jl. Raya Kopo Bihbul No. 45 Bandung
                    </span>
                                        </p>
                                    </Item>
                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${mainfont}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: mainfont }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${mainfont}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: mainfont }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                    <Item>
                                        <Col
                                            onClick={() => this.setState({ shownew: !shownew })}
                                            xs={11} md={4}
                                            style={{
                                                border: `2px solid ${mainfont}`,
                                                borderRadius: '10px'
                                            }}
                                            className="btn p-2 mx-2 my-2">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/2750/2750740.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: mainfont }}>
                                                    <b>New Normal Rules</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                {
                                    shownew ? (<Container className="text-center dinny py-3" >
                                        <Item>
                                            <Col className="py-3 px-sm-5" style={{
                                                border: `2px solid ${mainfont}`,
                                                borderRadius: '10px', fontFamily: 'Josefin Sans, sans-serif'
                                            }}>
                                                <h1 className="fs30"
                                                    style={{

                                                        fontFamily: 'Marck Script, cursive',
                                                        color: mainfont,
                                                        fontSize: '48px',
                                                        fontWeight: 700
                                                    }}>
                                                    New Normal Rules


          </h1>
                                                <Item>
                                                    <Col xs={12}>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Regarding this current COVID-19 pandemic, in order to maintain physical distancing that is currently being applied, unfortunately, we are only welcoming the invited guest. Thank you for your understanding.
          </p>
                                                    </Col>
                                                    <Col xs={6} sm={3}>
                                                        <img src={pw("reni-shidqi", 'mask.svg')} className="w-100 img-fluid p-sm-4" />
                                                        <Row>
                                                            <p className="cblack text-center w-100 fs16">
                                                                ALWAYS PUT<br />
                                        ON A MASK
        </p>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={6} sm={3}>
                                                        <img src={pw("reni-shidqi", 'hand.svg')} className="w-100 img-fluid p-sm-4" />
                                                        <Row>
                                                            <p className="cblack text-center w-100 fs16">
                                                                ALWAYS USE<br />HANDSANITIZER
      </p>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={6} sm={3}>
                                                        <img src={pw("reni-shidqi", 'distance.svg')} className="w-100 img-fluid p-sm-4" />
                                                        <Row>
                                                            <p className="cblack text-center w-100 fs16">
                                                                PHYSICAL<br />DISTANCING
      </p>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={6} sm={3}>
                                                        <img src={pw("reni-shidqi", 'salaman.svg')} className="w-100 img-fluid p-sm-4" />
                                                        <Row>
                                                            <p className="cblack text-center w-100 fs16">
                                                                DON’T SHAKING<br />HAND
      </p>
                                                        </Row>
                                                    </Col>
                                                </Item>
                                            </Col>
                                        </Item>

                                        <Item>
                                            <Col>
                                            </Col>

                                        </Item>

                                    </Container>
                                    ) : false
                                }


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">

                                    <Item>
                                        <div className="butterfly">
                                            <h1 style={{ color: '#86835dff', fontStyle: 'bold', fontWeight: 700, fontSize: '72px' }}>
                                                The Couple
                        </h1>
                                        </div>
                                    </Item>
                                    <Item>
                                        <div className="p-2">
                                        <img src={gambar(pw(id, "fix.png"), 95)} className="img-fluid w-100" />
                                        </div>
                                    </Item>
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4' style={{ color: '#86835dff' }}>
                                                            50.000 years before the sky was introduced to the sea, Allah wrote down your name next to me.<br /><br />
No matter how long it takes, how far we go, how tough it seems, then finally we find each other.

</p>
                                                    </Item>
                                                </div>
                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: mainfont
                                                    }}>
                                                        Send Your Wishes
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Hadir</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Tidak Hadir</label>
                                                                    </div>
                                                                    {!hadir ? false : (
                                                                        <>  <Alert variant='dark col-12 mr-4 '>
                                                                            <p className="f-14">
                                                                                Silahkan Pilih Jadwal Kedatangan
                                      </p>
                                                                            <div onClick={() => {
                                                                                this.setState({ sesi: 1 })
                                                                            }
                                                                            }
                                                                                className="pl-5">
                                                                                <input type="radio" id="featured-3" name="sesi" checked={sesi == 1 ? true : false} />
                                                                                <label for="featured-3">

                                                                                Sesi 1: 11.00 - 12.00 (Family Only) </label>
                                                                            </div>
                                                                            <div onClick={() => {
                                                                                this.setState({ sesi: 2 })
                                                                            }
                                                                            } className="pl-5">
                                                                                <input type="radio" id="featured-4" name="sesi" checked={sesi == 2 ? true : false} />
                                                                                <label for="featured-4"

                                                                                >
                                                                                   Sesi 2: 12.00 - 14.00</label>
                                                                            </div>
                                                                            <p className="f-14">
                                                                                Tamu undangan diharapkan hadir sesuai dengan sesi yang telah ditentukan

                                      </p>
                                                                        </Alert>



                                                                        </>

                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Item>

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: mainfont, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>

                                <Container className="py-5 px-md-5 col-md-8 px-3"
                                    style={{ color: '#86835dff', fontFamily: 'Patrick Hand, cursive' }}
                                >

                                    <Row className="position-relative px-5 py-5" style={{
                                        backgroundColor: 'rgba(255,255,255,0.1)'
                                    }}>
                                        <h1 className="peri w-100 text-center">
                                            Ucapan dan doa
                                        </h1>
                                        <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', top: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', top: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="mirror img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', bottom: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="flip180-m img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', bottom: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className=" flip180  img-fluid w-100" />
                                        </Col>
                                        <Row style={{ maxHeight: '50vh', overflowY: 'scroll', width: '100%' }} className="scroller">
                                            {comment.map((v, i) => {
                                                return (
                                                    <Row className="w-100 text-center mx-auto">
                                                        <Col></Col>
                                                        <Col xs={12} md={6} className="py-2">
                                                            <p className="w-100 text-center my-auto" style={{ fontSize: '18px' }}>
                                                                " {decodeURI(v.pesan)} " <br /> - {v.dari} -
                                                            </p>
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>
                                                )
                                            })
                                            }
                                        </Row>

                                    </Row>

                                </Container>


                                <Container classname="py-3" style={{ color: 'black' }}>
                                    <p className="text-center w-100" style={{ fontFamily: 'Patrick Hand, cursive' }} >
                                        Best Regards
                    </p>
                                    <h1 className="text-center w-100" style={{ fontFamily: 'Parisienne, cursive', }}>
                                        {inisial_ce} & {inisial_co}
                                    </h1>
                                </Container>

                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

